<template>
    <div class="TeamTestList">
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div slot="header" class="clearfix">

                </div>
            </div>


            <!-- 列表 -->
            <el-table :data="deviceAnnexList" border  style="width: 100%; font-size: 13px;" v-loading="loading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="deviceRealName" label="仪器仪表实际名称" align="center"> </el-table-column>
                <el-table-column prop="spareCode" label="附件编号" align="center">
                </el-table-column>
                <el-table-column prop="spareName" label="附件名称" align="center">
                </el-table-column>
                <el-table-column prop="spareImage_text" label="附件图片" align="center" width="150px">
                    <template slot-scope="scope">
                        <div v-if="scope.row.spareImage != null && scope.row.spareImage != ''">
                            <div class="demo-image__preview">
                                <el-image style="width: 100px; height: 100px" :src="scope.row.spareImage_text"
                                    :preview-src-list="scope.row.spareImgList">
                                </el-image>
                            </div>

                        </div>
                    </template>

                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="deviceAnnexFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]"
                :page-size="deviceAnnexFram.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>
<script>
import { serchDeviceAnnex} from '@/api/deviceAnnex.js'
export default {
    props: {
        did: {
            type: [String, Number]
        }
    },
    data() {
        return {
            deviceAnnexList: [],
            deviceAnnexFram: {
                did: this.did,
                pageNum: 1,
                pageSize: 10,
            },
            total: 0,
            loading: false,
            path: process.env.VUE_APP_URL + this.UploadPath
        }
    },
    mounted() {
        this.loadDeviceAnnex()
    },
    methods: {

        handleSizeChange(val) {
            this.deviceAnnexFram.pageSize = val;
            this.deviceAnnexFram.pageNum = 1;
            this.loadDeviceAnnex();
        },
        handleCurrentChange(val) {
            this.deviceAnnexFram.pageNum = val;
            this.loadDeviceAnnex();
        },
        loadDeviceAnnex() {
            this.loading = true
            serchDeviceAnnex(this.deviceAnnexFram).then(res => {
                setTimeout(() => {
                    this.loading = false
                    if (res.code === '000000') {
                        this.deviceAnnexList = res.t.list
                        let count = 1;
                        this.deviceAnnexList.forEach(item => {
                            item.seq = count++
                            const listImg = item.spareImage.split(";")
                            var imgs = []
                            listImg.forEach(item2 => {
                                if (item2 != null && item2 != '') {
                                    imgs.push(this.path + item2)
                                }
                            })
                            item.spareImage_text = this.path + item.spareImage.split(";")[0]
                            item.spareImgList = imgs
                        })
                    }

                }, 500)
            })
        },

        //搜索
        handleSearch() {
            this.loadDeviceAnnex(this.deviceAnnexFram);
        },
        //重置
        handleReset() {
            this.deviceAnnexFram = {
                did: this.did,
                shType: null,
                pageNum: 1,
                pageSize: 10,
            };
            this.loadDeviceAnnex(this.deviceAnnexFram);
        }
    }
}
</script>



<style lang="scss" scoped>
.TeamTestList {

    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }

        .clearfix {
            text-align: left;
            margin-bottom: 20px;
        }
    }

    .delColor {
        color: red;

    }

}
</style>